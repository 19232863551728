<template>
    <form ref="form" name="coampayForm" :action="gateway" method="post">
        <input type="hidden" name="CPID" v-model="cpid" />
        <input type="hidden" name="AMOUNT" v-model="paymentAmount" />
        <input type="hidden" name="ORDERID" v-model="orderNo" />
        <input type="hidden" name="ITEMNAME" v-model="orderName" />
        <input type="hidden" name="USERNAME" v-model="buyerName" />
        <input type="hidden" name="USERAGENT" v-model="userAgent" />
        <!-- <input type="hidden" name="BYPASSVALUE" v-model="bypassValue" /> -->
        <input type="hidden" name="RETURNURL" v-model="returnUrl" />
        <input type="hidden" name="DATA" v-model="encText" />
    </form>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
export default {
    data() {
        return {
            // 결제정보
            cpid: null,
            gateway: null,
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderName,
            paymentAmount: +(this.$route.query.paymentAmount || 0),
            paymentMethod: "creditcard",
            buyerName: this.$route.query.buyerName,
            encText: null,
        };
    },
    mounted() {
        window.coampayComplete = (payload) => {
            window?.parent?.coampayComplete(payload);
            window?.opener?.coampayComplete(payload);
        };

        this.auth();
    },
    methods: {
        async auth() {
            const { paymentAmount, orderNo } = this.$data;
            const { cpid, gateway } = await api.plugins.coampay.config.get();
            const { encText } = await api.plugins.coampay.generate({ cpid, paymentAmount, orderNo });

            this.cpid = cpid;
            this.gateway = gateway;
            this.encText = encText;

            this.$nextTick(() => {
                var form = this.$refs.form;

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    // 모바일 환경일때 처리
                }
                else{
                    // PC 환경일때 처리
                    var width = 500;
                    var height = 477;
                    var leftpos = screen.width / 2 - (width / 2);
                    var toppos = screen.height / 2 - (height / 2);
                    var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;

                    var modal = window.open('about:blank', "coampayPopup", feature);
                    if (modal == null) {
                        //팝업 차단여부 확인
                        alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                    }

                    form.target = "coampayPopup";
                }
                
                //인코딩 euc-kr 처리
                if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent)) {
                    var befCharset = document.charset;
                    document.charset = "euc-kr";
                    // window.document.charset = "utf-8";
                    // document.charset = befCharset;
                } else {
                    this.$refs.form.acceptCharset = "euc-kr";
                    // this.$refs.form.acceptCharset = "utf-8";
                }

                form.action = gateway;
                form.method = "post";
                form.submit();

                if (befCharset) document.charset = befCharset;
            });
        },
    },
    computed: {
        returnUrl() {
            return `${window.location.origin}/api/plugins/coampay/response`;
        },
        userAgent() {
            // return "WP"
            return (detect == "pc") ? "WP" : "WM";
        },
    },
};
</script>
