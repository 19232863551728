var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "name": "coampayForm",
      "action": _vm.gateway,
      "method": "post"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.cpid,
      expression: "cpid"
    }],
    attrs: {
      "type": "hidden",
      "name": "CPID"
    },
    domProps: {
      "value": _vm.cpid
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.cpid = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    attrs: {
      "type": "hidden",
      "name": "AMOUNT"
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderNo,
      expression: "orderNo"
    }],
    attrs: {
      "type": "hidden",
      "name": "ORDERID"
    },
    domProps: {
      "value": _vm.orderNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    attrs: {
      "type": "hidden",
      "name": "ITEMNAME"
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "name": "USERNAME"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userAgent,
      expression: "userAgent"
    }],
    attrs: {
      "type": "hidden",
      "name": "USERAGENT"
    },
    domProps: {
      "value": _vm.userAgent
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.userAgent = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.returnUrl,
      expression: "returnUrl"
    }],
    attrs: {
      "type": "hidden",
      "name": "RETURNURL"
    },
    domProps: {
      "value": _vm.returnUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.returnUrl = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.encText,
      expression: "encText"
    }],
    attrs: {
      "type": "hidden",
      "name": "DATA"
    },
    domProps: {
      "value": _vm.encText
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.encText = $event.target.value;
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }